import React from 'react';
import { graphql } from 'gatsby';
import tw from 'twin.macro';
import { css } from '@emotion/core';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ImageHeader from '../components/ImageHeader';
import Traits from '../components/index/Traits';
import Pauschal from '../components/index/Pauschal';
import LocationList from '../components/index/LocationList';

const HomePage = ({ data }) => (
  <Layout>
    <SEO title="Startseite" fluid={data.cover.childImageSharp.fluid} />
    <ImageHeader
      fluid={data.cover.childImageSharp.fluid}
      title={data.site?.title}
      size="fullheight"
      subtitle={data.site?.subtitle}
    >
      <div
        css={[
          css`
            background-color: rgba(229, 229, 229, 0.44);
          `,
          tw`flex px-6 py-4 mt-24 font-sans text-lg rounded-sm sm:text-2xl sm:mt-48`,
        ]}
      >
        <input
          css={tw`w-auto px-2 mr-4 rounded-sm outline-none sm:pr-24`}
          placeholder="Wohin soll es gehen?"
        />
        <button
          css={tw`px-4 py-2 font-medium transition-colors rounded-sm bg-brand-orange hover:bg-brand-darkOrange`}
          type="submit"
        >
          Suchen
        </button>
      </div>
    </ImageHeader>

    <Traits />
    <LocationList />
    <Pauschal />
    <div
      css={tw`flex flex-col items-center px-8 py-8 text-center bg-brand-orange`}
    >
      <h1 css={tw`font-serif text-3xl sm:text-5xl`}>
        Planen Sie mit uns Ihre nächste Reise!
      </h1>
      <p css={tw`max-w-4xl my-8 text-lg sm:text-2xl`}>
        Bei uns wird jeder Wunsch erfüllt. Egal ob Familienreise, mit dem
        Partner oder alleine! Unsere Experten freuen sich auf Ihre individuellen
        Wünsche
      </p>
      <button
        type="button"
        css={tw`px-3 py-2 mx-auto text-xl font-bold border-4 border-black rounded-full sm:px-6 sm:py-4 sm:text-3xl hover:bg-brand-darkOrange`}
      >
        Kontaktieren Sie uns
      </button>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "index/cover_img.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site: sanityHome(id: { eq: "9434802f-1a13-5efa-af65-daf892d953e9" }) {
      title
      subtitle
    }
  }
`;

export default HomePage;
