import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { BsPersonSquare } from 'react-icons/bs';
import { FaRegHandshake } from 'react-icons/fa';
import { BiWorld } from 'react-icons/bi';
import tw from 'twin.macro';
import Hero from '../Hero';
import Heading from '../Heading';

const TraitsQuery = graphql`
  {
    sanityHome(id: { eq: "9434802f-1a13-5efa-af65-daf892d953e9" }) {
      icons {
        bullet1 {
          title
          content
        }
        bullet2 {
          title
          content
        }
        bullet3 {
          title
          content
        }
      }
    }
  }
`;

const Trait = ({ logo, title, children }) => (
  <div css={tw`flex flex-col items-center`}>
    <div
      css={tw`flex items-center justify-center w-16 h-16 p-2 text-3xl rounded-full bg-brand-lightOrange lg:h-24 lg:w-24 text-brand-orange lg:text-5xl`}
    >
      {logo}
    </div>
    <h1 css={tw`my-4 font-serif text-3xl lg:text-5xl`}>{title}</h1>
    <p css={tw`max-w-md text-base lg:text-lg`}>{children}</p>
  </div>
);

const Traits = () => {
  const { sanityHome } = useStaticQuery(TraitsQuery);
  return (
    <Hero>
      <div>
        <Heading>Was uns ausmacht</Heading>
      </div>
      <div
        css={tw`grid items-center justify-center flex-1 grid-cols-1 gap-8 my-16 lg:gap-0 lg:grid-cols-3`}
      >
        <Trait logo={<BsPersonSquare />} title={sanityHome.icons.bullet1.title}>
          {sanityHome.icons.bullet1.content}
        </Trait>
        <Trait logo={<FaRegHandshake />} title={sanityHome.icons.bullet2.title}>
          {sanityHome.icons.bullet2.content}
        </Trait>
        <Trait logo={<BiWorld />} title={sanityHome.icons.bullet3.title}>
          {sanityHome.icons.bullet3.content}
        </Trait>
      </div>
    </Hero>
  );
};
export default Traits;
