import React from 'react';
import Image from 'gatsby-image';
import { FaPlaneDeparture } from 'react-icons/fa';
import { MdVideoCall } from 'react-icons/md';
import { AiOutlineSafety } from 'react-icons/ai';
import { graphql, useStaticQuery } from 'gatsby';
import tw from 'twin.macro';
import Hero from '../Hero';
import Heading from '../Heading';

const PauschalQuery = graphql`
  {
    sanityHome(id: { eq: "9434802f-1a13-5efa-af65-daf892d953e9" }) {
      exlusives {
        title
        subtitle
        image {
          asset {
            fluid(maxHeight: 500, maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
        exclusive1 {
          title
          content
        }
        exclusive2 {
          title
          content
        }
        exclusive3 {
          title
          content
        }
        exclusive4 {
          title
          content
        }
      }
    }
  }
`;

const Service = ({ icon, title, children }) => (
  <div
    css={tw`relative flex flex-col justify-center rounded-sm shadow-md bg-brand-gray`}
  >
    <div css={tw`absolute text-5xl text-brand-orange top-4 xl:top-8 left-8`}>
      {icon}
    </div>
    <div css={tw`px-8 pt-16 pb-4 text-left xl:pt-24 sm:pb-8`}>
      <h2 css={tw`mb-3 font-serif text-2xl lg:text-4xl`}>{title}</h2>
      <p css={tw`text-lg`}>{children}</p>
    </div>
  </div>
);

const Pauschal = () => {
  const {
    sanityHome: { exlusives },
  } = useStaticQuery(PauschalQuery);

  const services = Object.values(exlusives).splice(3);

  return (
    <Hero>
      <div>
        <Heading>Unser Service</Heading>
      </div>
      <div css={tw`grid my-6 xl:my-12 sm:px-16`}>
        <div css={tw`grid grid-cols-1 gap-12 sm:gap-4 sm:grid-cols-2`}>
          <Service
            key={services[0].title}
            title={services[0].title}
            icon={<FaPlaneDeparture />}
          >
            {services[0].content}
          </Service>
          <Service
            key={services[1].title}
            title={services[1].title}
            icon={<MdVideoCall />}
          >
            {services[1].content}
          </Service>
          <Service
            key={services[2].title}
            title={services[2].title}
            icon={<AiOutlineSafety />}
          >
            {services[2].content}
          </Service>
          <Service
            key={services[3].title}
            title={services[3].title}
            icon={<FaPlaneDeparture />}
          >
            {services[3].content}
          </Service>
        </div>
        {/* <div css={tw`justify-center hidden pl-8 xl:flex`}>
          <Image
            css={tw`w-7/12 h-full rounded-sm`}
            fluid={exlusives.image.asset.fluid}
          />
        </div> */}
      </div>
    </Hero>
  );
};

export default Pauschal;
