import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import tw from 'twin.macro';
import { css } from '@emotion/core';
import Heading from '../Heading';
import Hero from '../Hero';

const LocationQuery = graphql`
  {
    sanityHome(id: { eq: "9434802f-1a13-5efa-af65-daf892d953e9" }) {
      favorites {
        fav1 {
          title
          content
          image {
            asset {
              fluid(maxHeight: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        fav2 {
          title
          content
          image {
            asset {
              fluid(maxHeight: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        fav3 {
          title
          content
          image {
            asset {
              fluid(maxHeight: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

const Location = ({ img, title, children }) => (
  <div
    css={[
      css`
        min-height: 400px;
      `,
      tw`relative font-serif text-left h-5/6`,
    ]}
  >
    <Image fluid={img} css={tw`h-full rounded-md`} />
    <div css={tw`absolute top-0 z-10 px-2 py-4`}>
      <h2 css={tw`text-4xl lg:text-5xl`}>{title}</h2>
      <h3 css={tw`text-xl lg:text-2xl`}>{children}</h3>
    </div>
  </div>
);

const LocationList = () => {
  const {
    sanityHome: { favorites },
  } = useStaticQuery(LocationQuery);
  return (
    <Hero css={tw`bg-brand-bluegray`}>
      <div>
        <Heading>Beliebte Ziele</Heading>
      </div>
      <div
        css={tw`grid items-center justify-center flex-1 grid-cols-1 gap-6 gap-24 px-6 my-16 lg:gap-16 md:grid-cols-3 lg:px-16`}
      >
        {Object.values(favorites).map(({ title, image, content }) => (
          <Location key={title} title={title} img={image?.asset?.fluid}>
            {content}
          </Location>
        ))}
      </div>
    </Hero>
  );
};

export default LocationList;
